$(document).ready(function() { 
    
    let carouselWidth = $(".carousel-inner")[0].scrollWidth;
    let cardWidth = $(".carousel-item").width();
    let scrollPosition = 0;
    let dataIndex = 0;
    
    $(".carousel-control-next").on("click", function () {
        if (scrollPosition < (carouselWidth - cardWidth * 4)) { //check if you can go any further
          scrollPosition += cardWidth;  //update scroll position
          $(".carousel-inner").animate({ scrollLeft: scrollPosition },600); //scroll left
        }
    });

    $(".carousel-control-prev").on("click", function () {
        if (scrollPosition > 0) {
          scrollPosition -= cardWidth;
          $(".carousel-inner").animate(
            { scrollLeft: scrollPosition },
            600
          );
        }
    });

    $(".carousel-item img").hover(function(){
        let thumbailsSrc = $(this).attr("src");
        let newSrc = thumbailsSrc.replace("small", "large");
        let newIndex = $(this).parent().data("index");
        
        if(newIndex != dataIndex){
            $('.big-img img').removeClass('reverseHidden').addClass('hidden');
            setTimeout(function(){
                $('.big-img img').removeClass('hidden').addClass('reverseHidden');
                $('.big-img img').attr('src', newSrc);
            },300)
        }

        dataIndex = newIndex;
        //console.log(bigimgSrc);
    })
});