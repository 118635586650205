class Popup {
    constructor(Modal) {
        this.modal = Modal;
        this.ajaxPopup = true
        //path de l'action editer de fos user : formulaire de modification
        let uri = window.location.pathname;
        let url = '';
        
        if(uri == '/'){
            url = window.location.origin + '/ajax/popup-index';
        }

        else if(uri == '/location/appartements'){
            url = window.location.origin + '/ajax/popup-location';
        }

        else if(uri != '/contact/hotel-le-catalan' || uri != '/sitemap/sitemap.xml'){
            url = window.location.origin + '/ajax/popup' + window.location.pathname;
        }

        else{
            this.ajaxPopup = false
        }

        if(this.ajaxPopup){
            
            $.ajax({
                type: 'get',
                url: url,
                async: false,
            }).done(function(data){
                if( data ){
                    $( 'body' ).append( data );
                }
            });
            
            if($('#myModalPopup').length){

                let myModal = new this.modal($('#myModalPopup'), {
                    keyboard: false
                })

                setTimeout(function() { 
                    myModal.show();
                }, 2000);
            }

            let myModalEl = document.getElementById('myModalPopup')
            
            if(myModalEl){
                myModalEl.addEventListener('hide.bs.modal', function (event) {
                    const d = new Date();
                    const exquarthour = 1;
                    d.setTime(d.getTime() + (exquarthour*15*60*1000));
                    let expires = "expires="+ d.toUTCString();
                    let cname = window.location.pathname;

                    document.cookie = 'popup-' + ( cname == '/' ? 'index' : cname.substring(1) ) + "=opened;" + expires + ";path=/";
                })
            }
        }
    }

}

export default Popup;